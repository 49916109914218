import React from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Layout,
  Space,
  Alert,
  Card
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { presetDarkPalettes } from "@ant-design/colors";
import { useMutation, useQuery } from "react-query3";
import { LoginPage as LoginPageType } from "./../../types";
import axios from "axios";
import { Menu } from 'antd';
import { Helmet } from 'react-helmet';
import { toRelativeUrl } from "components/utils/utils";

// login to admin console
export function useLogin() {
  return useMutation(
    async (credentials: { username: string; password: any }) => {
      let res = await axios.post(
        toRelativeUrl(`/api/v1/signin${window.location.search}`),
        JSON.stringify(credentials),
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return res.data;
    },
    // {
    //   onError: (error: AxiosError, creds) => {
    //     // @ts-ignore
    //     return error.response.data?.errorMessage;
    //   },
    //   onSuccess: (data, creds) => {
    //     //@ts-ignore
    //     window.location.href = data?.returnUrl;
    //   },
    // }
  );
}
export default function CustomLogin() {
  const { data } = useQuery<LoginPageType>("/loginPage");
  const { mutateAsync, isLoading, data: userInfo } = useLogin();

  async function login(values: { username: string; password: any }) {
    await mutateAsync({
      username: values.username,
      password: values.password,
    }, {
      onSuccess: (data) => {
        if (data?.returnUrl) {
          window.location.href = data?.returnUrl
        }
      }
    });
  }

  var links = <React.Fragment />
  if ((data?.links && data?.links.length > 0) || data?.appBarLogo) {
    links = <header style={
      {
        backgroundColor: data?.headerColor
      }
    }>
      {data?.appBarLogo && <img src={data?.appBarLogo} alt="app bar logo" style={{ padding: '10px' }} />}
      <Menu mode="horizontal" style={{
        backgroundColor: data?.headerColor
      }}>
        {
          data?.links && data?.links.map(x => <Menu.Item><a href={x.url} style={
            {
              color: data?.headerFontColor
            }
          }>{x.text}</a></Menu.Item>)
        }
      </Menu>
    </header >
  }

  var stylesheets = <React.Fragment />
  if (data?.cssStylesheet && data?.cssStylesheet.length > 0) {
    stylesheets = <Helmet>
      {data.cssStylesheet.map((m, i) => <link rel="stylesheet" href={m} integrity={data?.cssStylesheetIntegrity ? data?.cssStylesheetIntegrity[i] : null} />)}
    </Helmet>
  }


  return (
    <React.Fragment>
      {links}
      <Helmet>
        <title>{data?.title}</title>
        {
          data?.primaryFontColor && (
            <style>{`
                  input {
                    color: ${data?.primaryFontColor} !important;
                  }
              `
            }
            </style>
          )
        }
      </Helmet>
      {stylesheets}
      <Layout
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: data?.secondaryColor
        }}
      >
        <Space size={24} direction="vertical" align="center">
          {data?.image && <img src={data?.image} alt="Logo" />}
          <Typography>
            <Typography.Paragraph
              style={{
                textAlign: "center",
                fontFamily: "SFProDisplay-Semibold",
                maxWidth: 340,
                color: data?.primaryFontColor
              }}
            >
              {data?.title}
            </Typography.Paragraph>
          </Typography>
          <Card
            bordered={false}
            style={{
              maxWidth: 330,
              maxHeight: 360,
              padding: 24,
              flex: "unset",
              backgroundColor: data?.primaryColor
            }}
          >
            <Space size={24} direction="vertical">
              <Typography style={{ width: "100%" }}>
                <Typography.Title
                  level={5}
                  style={{
                    width: "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: data?.primaryFontColor
                  }}
                >
                  Log in to your account
                </Typography.Title>
              </Typography>

              <Form
                name="normal_login"
                style={{ maxWidth: 330, maxHeight: 360 }}
                onFinish={(values) => login(values)}
              >
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your Username!" },
                  ]}
                  style={
                    {
                      color: data?.primaryFontColor
                    }
                  }
                >
                  <Input
                    prefix={
                      <UserOutlined
                        style={{ color: data?.primaryFontColor || presetDarkPalettes["blue"][6] }}
                      />
                    }
                    autoFocus
                    bordered={false}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  style={
                    {
                      color: data?.primaryFontColor
                    }
                  }
                >
                  <Input
                    prefix={
                      <LockOutlined
                        style={{
                          color: data?.primaryFontColor || presetDarkPalettes["blue"][6]
                        }}
                      />
                    }
                    bordered={false}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    block
                    style={
                      {
                        borderColor: data?.secondaryColor,
                        backgroundColor: data?.secondaryColor,
                        color: data?.secondaryFontColor
                      }
                    }
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
              {userInfo?.errorMessage && (
                <Form.Item>
                  <Alert message={userInfo?.errorMessage} type="error" showIcon />
                </Form.Item>
              )}
            </Space>
          </Card>
          <Typography>
            <Typography.Paragraph style={
              {
                color: data?.secondaryFontColor
              }
            }>{data?.copyright}</Typography.Paragraph>
          </Typography>
        </Space>
      </Layout>
    </React.Fragment>
  );
}
