import queryClient from "components/utils/queryClient";
import { EndpointDocumentation } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const endpointDocumentationSchema: CustomSchema<EndpointDocumentation>[] = [
    {
        name: "name",
        label: "Name",
        component: "input",
        tooltip: "The name of the endpoint documentation.",
        required: true,
        uniqe: true,
    },
    {
        name: "url",
        label: "Url",
        tooltip: "The URL to access this documentation.",
        component: "input",
        required: true,
        uniqe: false,
    },
    {
        name: "description",
        label: "Description",
        tooltip: "The description of the endpoint documentation.",
        component: "input",
        required: false,
        uniqe: false,
    },
    {
        name: "authentication",
        label: "Authentication",
        component: "authSwitch",
        valuePropName: 'checked',
        required: false,
        uniqe: false,
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.authentication !== curValues.authentication || curValues.authentication === true
        },
        children: ({ getFieldValue }) => {
            const name = getFieldValue("name")
            let defaultRoles = queryClient.getQueryData<EndpointDocumentation[]>(`/endpoint`)?.find(endpoint => endpoint?.name === name)?.role
            return getFieldValue("authentication") === true ? (
                <Field
                    component="roleTags"
                    name="role"
                    label="Role"
                    preserve={false}
                    required={true}
                    initialValue={defaultRoles || null}
                    dependencies={['authentication']}
                    uniqe={false}
                />
            ) : null;
        },
    },
];
